var $ = require("jquery");
window.scrollToElement = require('scroll-to-element');

$(function () {
  $(document).on('click', 'a.scroll-to', function (e) {
    e.preventDefault();
    const id = $(this).attr('href');
    if (id && id !== '#' && $(id).length) {
      scrollToElement(id);
    }
  });

  var onOptOutFrameLoadCount = 0;

  $('#optout').on('click', function (e) {
    e.preventDefault();
    $('#opt-form')[0].submit();
  });

  $('#optout-iframe').on('load', function (e) {
    onOptOutFrameLoadCount += 1;
    if (onOptOutFrameLoadCount === 1) {
      window.alert('Подбор рекламы по интересам отключен');
    }
  });

  $('#xs-menu-toggle').on('click', function (e) {
    $('#xs-menu').toggleClass('on');
  });
  $('#xs-menu a').on('click', function (e) {
    window.setTimeout(function () {
      $('#xs-menu').toggleClass('on');
    }, 500);
  });

  $('#send-form-main').on('submit', function (e) {
    e.preventDefault();

    var $form = $(this);
    $('#send-preloader').addClass('on');

    $.ajax($form.attr('action'), {
      crossDomain: true,
      data: $form.serialize(),
      type: 'POST'
    }).done(function (e) {
      $('#send-preloader').removeClass('on');
      $('#send-success').addClass('on');
    });
  });
});
